import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import './styles/Receipt.css';

function DeleteDuplicates() {
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
  
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    navigate('/loginpage');
  }

    const [DuplicateRecordsData, setDuplicateRecordsData] = useState([]);
    const [CenterMasterData, setCenterMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };
      
      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      centerno: '',
      routecode: '',
      bookdate: '',
      centername: '',
      routename: '',
      centerid: '',
      customer_centerwiseid: '',
      customer_name: '',
      transactionid: '',
      customer_status: '',
      outstanding_above: '',
      ShowAllRecords: false
    });

    useEffect(() => {
        if (UserMasterDataGlobal.length > 0) {
          // if(!defaultcenter){
            axios.get(apireq05042024+`CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)  
            .then((response) => {
                setCenterMasterData(response.data.CenterMasterData);
                setFormData({
                    ...formData,
                    centerno: response.data.CenterMasterData[0].centerid,
                })
            })
            .catch((error) => console.error('Error fetching data:', error));
          // }
        }
      }, [UserMasterDataGlobal]);        

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

 
      const location = useLocation(); // Get the current location, including the query parameters
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            // Use URLSearchParams to extract query parameters
            const searchParams = new URLSearchParams(location.search);
            const centerno = searchParams.get('centerno');
            const lineno = searchParams.get('lineno');
            const month = searchParams.get('month');
            const year = searchParams.get('year');
    
            // If query parameters are present, update the formData and fetch records
            if (centerno && lineno && month && year) {
              setFormData({
                ...formData,
                centerid: centerno,
                routename: lineno,
                month: month,
                year: year,
              });
    
              getDuplicateRecords("0", centerno, lineno, month, year);
            }
          } catch (error) {
            console.error('Error fetching data for editing:', error);
          }
        };
        if(UserMasterDataGlobal?.[0]?.CompanyId) {
          fetchData();
        }
      }, [location.search, formData.ShowAllRecords, UserMasterDataGlobal]); // Depend on location.search to react to URL changes
   
  async function getDuplicateRecords(ShowAllRecords, centerid=null, routename=null, month=null, year=null){
    try {

      const data = {
        ShowAllRecords: ShowAllRecords,
        centerid: centerid,
        routename: routename,
        month: month,
        year: year,
        CompanyId: UserMasterDataGlobal?.[0]?.CompanyId
      };
      
      const response = await axios.post(apireq05042024+'MonthlySheet/getDuplicateRecords', data);

      if(response.status === 200) {
        // console.log(typeof response.data);
        const dataArray = Object.values(response.data);        
        setDuplicateRecordsData(dataArray);
        // setDuplicateRecordsData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; // Return null in case of error
    }
  }

  const MySwal = withReactContent(Swal);

  async function DeleteRecord(c) {

    const response = await axios.post(apireq05042024+`DeleteDuplicates/DeleteDuplicatesFunction`, c);
  
    if (response.status == 200) {
      if(response.data.message=="Successfully deleted") {

        setDuplicateRecordsData((prevData) => 
          prevData.map(innerArray => 
            innerArray.filter(record => {
              console.log('Checking transaction_id:', record.transaction_id); // Log each transaction_id
              console.log('Cskd:', c.transaction_id); // Log the transaction_id to remove
              return record.transaction_id !== c.transaction_id;
            })
          )
        );        
         
      } 
      else{
        // MySwal.fire({
        //     html: <b>{response.data}</b>,
        //     icon: 'error'
        // }) 
      }
    } else {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Record not deleted! Please try once again or call the developer</b>,
              icon: 'error'
          })
      }
  }

  const getformatteddate = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '9px', cursor: 'default', fontSize: 20 }} className="text-center"> Outstanding list</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', color: 'rgb(230 238 247)', backgroundColor: 'rgb(0, 152, 121)', marginTop:'-8px'}}>
          <a className="navbar-brand" style={{color: 'rgb(230 238 247)'}} href="/" >Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer', color: 'rgb(230 238 247)' }}>Log out</a>
          </div>
      </nav>

      <div className="row mt-2">
          <div className="offset-10 col-md-2">
            <button
              type="submit"
              className="btn btn-primary w-100"
              style={{ marginTop: '32px' }}
              onClick={(event) => getDuplicateRecords("1")}
            >
              Show All
            </button>
          </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'left'}}>Tran Date</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '6%', textAlign: 'left'}}>Customer Name</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'left'}}>Center</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}>Line No</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}>Qty</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '5%', textAlign: 'right'}}>Rate</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'right'}}>Amt</th>
                  <th style={{backgroundColor:'#009879', color:"white", width: '9%', textAlign: 'center'}}></th>                  
                </tr>
              </thead>
              <tbody>
                {Array.isArray(DuplicateRecordsData) && DuplicateRecordsData.length > 0 ? (
                DuplicateRecordsData.flatMap((innerArray, outerIndex) => {
                  return [
                    ...innerArray.map((c, index) => (
                      <tr key={c.transaction_id}>
                        <td style={{ textAlign: 'left' }}>{getformatteddate(c.tran_date)}</td>
                        <td style={{ textAlign: 'left' }}>{c.customer_name}</td>
                        <td style={{ textAlign: 'left' }}>{getCenterNameById(c.centerid)}</td>
                        <td style={{ textAlign: 'right' }}>{c.routename}</td>
                        <td style={{ textAlign: 'right' }}>{Number(c.cowqty) > 0.0001 ? Number(c.cowqty) : Number(c.buffqty).toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{Number(c.cowrate) > 0.0001 ? Number(c.cowrate) : Number(c.buffrate).toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{Number(c.cowamt) > 0.0001 ? Number(c.cowamt) : Number(c.buffamt).toFixed(2)}</td>
                        <td style={{ textAlign: 'center' }}>
                          <button onClick={() => DeleteRecord(c)} className="btn btn-danger btn-sm ml-1">Delete</button>
                        </td>
                      </tr>
                    )),
                    // Add an empty row after each inner array
                    <tr key={`empty-row-${outerIndex}`}>
                      <td colSpan="8" style={{ height: '20px' }}></td>
                    </tr>
                  ];
                })
              ) : (
                <tr>
                  <td colSpan="7">No records found.</td>
                </tr>
              )}
            </tbody>
            </table>
          </div>

        </div>
      </div>

    </div>

    );

}

export default DeleteDuplicates;