// || Shree Ganeshay namha ||

// Import necessary modules from React and React Router
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your page components
import CustomerMaster from './pages/CustomerMaster';
import ProductMaster from './pages/ProductMaster';
import Navbar1 from './pages/Navbar1';
import UserRegistrationForm from './pages/UserRegistrationForm';
import LoginPage from './pages/LoginPage';
import Receipt from './pages/Receipt';
import DailyDeliverySheet from './pages/DailyDeliverySheet';
import CenterMaster from './pages/CenterMaster';
import RouteMaster from './pages/RouteMaster';
import Test2 from './pages/Test2';
import CustomerWiseSalesEntryCard from './pages/CustomerWiseSalesEntryCard';
import OutStandingReport from './pages/reports/OutStandingReport';
import MonthlySheet from './pages/MonthlySheet';
import DailySummaryReport from './pages/reports/DailySummaryReport';
import MonthlyCustomerWiseReport from './pages/reports/MonthlyCustomerWiseReport';
import ChequeTransactionReport from './pages/reports/ChequeTransactionReport';
import Company_register from './pages/commondb_files/Company_register';
import BankMaster from './pages/BankMaster';
import LedgerReport from './pages/reports/LedgerReport';
import HealthCheck from './pages/HealthCheck';
import DeleteDuplicates from './pages/DeleteDuplicates';

const App = () => {

    return (

        <
        Router >
        <
        Routes >
        <
        Route path = "/"
        exact element = { < Navbar1 / > }
        /> <
        Route path = "/ProductMaster"
        element = { < ProductMaster / > }
        /> <
        Route path = "/CustomerMaster"
        element = { < CustomerMaster / > }
        /> <
        Route path = "/registration"
        element = { < UserRegistrationForm / > }
        /> <
        Route path = "/loginpage"
        element = { < LoginPage / > }
        /> <
        Route path = "/Receipt"
        element = { < Receipt / > }
        /> <
        Route path = "/DailyDeliverySheet_rrrrr"
        element = { < DailyDeliverySheet / > }
        /> <
        Route path = "/CenterMaster"
        element = { < CenterMaster / > }
        /> <
        Route path = "/RouteMaster"
        element = { < RouteMaster / > }
        /> <
        Route path = "/Test2_rrrrr"
        element = { < Test2 / > }
        /> <
        Route path = "/CustomerWiseSalesEntryCard_rrrrr"
        element = { < CustomerWiseSalesEntryCard / > }
        /> <
        Route path = "/OutStandingReport"
        element = { < OutStandingReport / > }
        /> <
        Route path = "/MonthlySheet"
        element = { < MonthlySheet / > }
        /> <
        Route path = "/DailySummaryReport"
        element = { < DailySummaryReport / > }
        /> <
        Route path = "/MonthlyCustomerWiseReport"
        element = { < MonthlyCustomerWiseReport / > }
        /> <
        Route path = "/company_register"
        element = { < Company_register / > }
        /> <
        Route path = "/BankMaster"
        element = { < BankMaster / > }
        /> <
        Route path = "/ChequeTransactionReport"
        element = { < ChequeTransactionReport / > }
        />
        <
        Route path = "/LedgerReport"
        element = { < LedgerReport / > }
        />
        <
        Route path = "/HealthCheck_parth1"
        element = { < HealthCheck / > }
        />
        <
        Route path = "/DeleteDuplicates"
        element = { < DeleteDuplicates / > }
        />
        <
        /
        Routes > <
        /Router>


    );

};

export default App;